import { getCoopCase } from '@/api'
export default {
  data () {
    return {
      bannerImg: ''
    }
  },
  created () {
    this.getCoopCase()
  },
  methods: {
    async getCoopCase () {
      const res = await getCoopCase({ bannerType: this.bannerType })
      this.bannerImg = res.data.wapPic
    },
  }
}