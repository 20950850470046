<template>
	<div class=''>
		<mzSwiper :image='bannerImg'></mzSwiper>
		<!-- 导航栏 -->
		<div class="mr37 ml37 flex">
			<div class="w150 h44 fs12 fw-500 text-center lh44" :class="{'active':item.id==query.categoryId}"
				style="background: #F2F2F2;color: #787878;" v-for="(item,i) in tabList" :key="i"
				@click="checkTab(item.id)">
				{{item.categoryName}}
			</div>
		</div>
		<!-- //导航栏 -->

		<!-- 新闻列表 -->
		<div class="pr16 pl16 pb30">
			<div class="bg-f9 mt20" v-for="(item,i) in list" :key="i" @click="$router.push(`/newsD/${item.id}`)">
				<img :src="item.wapPic" style="object-fit: cover;" class="w343 h270">
				<div class="pt16 pb20 pl16 pr16">
					<div class="fs16 fw-500">{{item.contentName}}</div>
					<div class="mt8 fs12 color6">{{item.releaseTime}}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { getNewsCategory, getNewsList } from '@/api'
	import  bannerImgJs  from '@/mixin/bannerImg.js'
	export default {
		data() {
			return {
				tabList: [],
				query: {
					categoryId: null,
					pageNum: 1,
					pageSize: 10
				},
				count: 2,
				list: [],
				bannerType: 7
			};
		},

		components: {},
		mixins: [bannerImgJs],
		created() {
			this.getNewsCategory()
		},

		mounted() {},

		methods: {
			async getNewsCategory() {
				const res = await getNewsCategory()
				this.tabList = res.data
				this.query.categoryId = res.data[0].id
				this.getNewsList()
			},
			async getNewsList() {
				const res = await getNewsList(this.query)
				this.count = res.data.count
				this.list = res.data.obj
			},
			async checkTab(id) {
				this.query.categoryId = id
				this.getNewsList()
			}
		}
	}
</script>
<style lang='scss' scoped>
	.active {
		color: #000000 !important;
		background: #F7B500 !important;
	}
</style>
